import { type LineInputs } from "@fscrypto/domain/visualization/v3";
import { mergeDeep } from "remeda";
import { XYChartBuilder } from "./xy-chart";

export class LineOptionsBuilder extends XYChartBuilder<LineInputs> {
  type = "line" as const;
  constructor(input: LineInputs, config: Highcharts.Options) {
    super(input, config);
  }

  getHighchartsType() {
    return "line" as const;
  }

  build(data: Record<string, unknown>[]) {
    const config = super.build(data);
    return mergeDeep(
      {
        plotOptions: {
          line: {
            marker: {
              symbol: "circle",
              enabled: true,
            },
          },
        },
      },
      config as unknown as Record<string, unknown>,
    );
  }
}
