import { visualization } from "@fscrypto/domain";
import { CandlestickInputs } from "@fscrypto/domain/visualization/v3";
import type { Options } from "highcharts";
import Highcharts from "highcharts";
import * as R from "remeda";
import { averageData } from "../../data";
import { XYChartBuilder } from "./xy-chart";

type CandlestickDatum = { x: number; open: number; high: number; low: number; close: number };

export class CandlestickOptionsBuilder extends XYChartBuilder<CandlestickInputs> {
  constructor(
    public readonly input: CandlestickInputs,
    public readonly options: Options,
  ) {
    super(input, options);
  }
  get type() {
    return this.input.type;
  }

  getHighchartsType(): keyof Highcharts.PlotOptions {
    return "candlestick";
  }

  getData(data: Record<string, unknown>[]) {
    const { x, open, close, colors, high, low } = this.inputs.config;
    if (!x || !open || !close || !colors || !high || !low) return data;
    if (!visualization.v3.isDateType(x.type)) return data;
    const groupedByDate = averageData(data, x.key, [open.key, high.key, low.key, close.key]);
    const retval = groupedByDate.map(
      (d: Record<string, unknown>) =>
        ({
          x: new Date(d[x.key] as string).getTime(),
          open: d[open.key],
          high: d[high.key],
          low: d[low.key],
          close: d[close.key],
        }) as CandlestickDatum,
    );
    return R.sortBy(retval, (d) => d.x);
  }

  getSeries(data: Record<string, unknown>[]): Highcharts.SeriesCandlestickOptions[] {
    const yAxes = this.options.yAxis
      ? Array.isArray(this.options.yAxis)
        ? this.options.yAxis
        : [this.options.yAxis]
      : [];
    return [
      {
        type: "candlestick",
        data: (data as CandlestickDatum[]).map((d) => [d.x, d.open, d.high, d.low, d.close]),
        name: yAxes[0]?.title?.text ?? this.input.config.open?.key ?? this.options.title?.text,
      },
    ];
  }

  build(data: Record<string, unknown>[]): Highcharts.Options {
    const config = super.build(data);
    return R.mergeDeep(
      {
        xAxis: {
          ordinal: false,
        },
        plotOptions: {
          candlestick: {
            color: this.inputs.config.colors?.downwardTrend,
            lineColor: this.inputs.config.colors?.downwardTrend,
            upColor: this.inputs.config.colors?.upwardTrend,
            upLineColor: this.inputs.config.colors?.upwardTrend,
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function (this: Highcharts.TooltipFormatterContextObject) {
            const point = this.point as Highcharts.PointOptionsObject;
            if (
              point.open === undefined ||
              point.high === undefined ||
              point.low === undefined ||
              point.close === undefined
            ) {
              return false;
            }
            return `
              <div style="font-family: Arial, sans-serif; padding: 8px;">
                <div style="font-size: 14px; font-weight: bold; margin-bottom: 5px;">
                  ${Highcharts.dateFormat("%b %e, %Y, %H:%M:%S", this.x as number)}
                </div>
                <div style="font-size: 14px;">
                  <span style="color: #666;">O: </span><span style="font-weight: bold;">$${Highcharts.numberFormat(point.open as number, 2)}</span><br>
                  <span style="color: #666;">H: </span><span style="font-weight: bold;">$${Highcharts.numberFormat(point.high as number, 2)}</span><br>
                  <span style="color: #666;">L: </span><span style="font-weight: bold;">$${Highcharts.numberFormat(point.low as number, 2)}</span><br>
                  <span style="color: #666;">C: </span><span style="font-weight: bold;">$${Highcharts.numberFormat(point.close as number, 2)}</span>
                </div>
              </div>
            `;
          },
        },
      } satisfies Highcharts.Options,
      config as Record<string, unknown>,
    );
  }
}
