import { visualization } from "../..";
import {
  Inputs,
  VisualizationType,
  isBigNumberInput,
  isCandlestick,
  isHeatmapInput,
  isHistogramInput,
  isPieInput,
  isTableInput,
  isXYInput,
} from "./schema";
import { some } from "lodash-es";

type Fields = visualization.v3.ColumnTypePair[];

/**
 * Checks if a valid X-axis field is selected for XY chart types
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if X-axis requirement is met
 */
export function checkXAxisField(inputs: Inputs, fields: Fields): boolean {
  return isXYInput(inputs) && !!inputs.config?.x && fields.some((f) => f.name === inputs.config.x?.key);
}

/**
 * Checks if valid Y-axis fields are selected for XY chart types
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if Y-axis requirements are met
 */
export function checkYAxisFields(inputs: Inputs, fields: Fields): boolean {
  return (
    isXYInput(inputs) &&
    (inputs.config?.y ?? []).length > 0 &&
    some(inputs.config.y?.map((yKey) => fields.some((f) => f.name === yKey.key) ?? [false]))
  );
}

/**
 * Checks if a valid slice field is selected for pie charts
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if pie slice requirement is met
 */
export function checkPieSliceField(inputs: Inputs, fields: Fields): boolean {
  return isPieInput(inputs) && !!inputs.config?.slice && fields.some((f) => f.name === inputs.config.slice?.key);
}

/**
 * Checks if a valid value field is selected for pie charts
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if pie value requirement is met
 */
export function checkPieValueField(inputs: Inputs, fields: Fields): boolean {
  return isPieInput(inputs) && !!inputs.config?.value && fields.some((f) => f.name === inputs.config.value?.key);
}

/**
 * Checks if at least one column is visible in table visualization
 * @param inputs The visualization inputs configuration
 * @returns boolean indicating if table columns requirement is met
 */
export function checkTableColumns(inputs: Inputs): boolean {
  return isTableInput(inputs) && Object.values(inputs.config.columnOptions).some((option) => option.visible === true);
}

/**
 * Checks if a valid value field is selected for big number visualization
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if big number value requirement is met
 */
export function checkBigNumberField(inputs: Inputs, fields: Fields): boolean {
  return isBigNumberInput(inputs) && !!inputs.config?.valueKey && fields.some((f) => f.name === inputs.config.valueKey);
}

/**
 * Checks if a valid value field is selected for histogram visualization
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if histogram value requirement is met
 */
export function checkHistogramValueField(inputs: Inputs, fields: Fields): boolean {
  return (
    isHistogramInput(inputs) && !!inputs.config?.y?.[0]?.key && fields.some((f) => f.name === inputs.config.y?.[0]?.key)
  );
}

/**
 * Checks if a valid X-axis field is selected for heatmap visualization
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if heatmap X-axis requirement is met
 */
export function checkHeatmapXAxis(inputs: Inputs, fields: Fields): boolean {
  return isHeatmapInput(inputs) && !!inputs.config.x && fields.some((f) => f.name === inputs.config.x?.key);
}

/**
 * Checks if a valid Y-axis field is selected for heatmap visualization
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if heatmap Y-axis requirement is met
 */
export function checkHeatmapYAxis(inputs: Inputs, fields: Fields): boolean {
  return isHeatmapInput(inputs) && !!inputs.config?.y && fields.some((f) => f.name === inputs.config.y?.[0]?.key);
}

/**
 * Checks if a valid value field is selected for heatmap visualization
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if heatmap value requirement is met
 */
export function checkHeatmapValueField(inputs: Inputs, fields: Fields): boolean {
  return isHeatmapInput(inputs) && !!inputs.config?.value && fields.some((f) => f.name === inputs.config.value?.key);
}

/**
 * Checks if a valid X-axis field is selected for candlestick chart
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if candlestick X-axis requirement is met
 */
export function checkCandlestickXAxis(inputs: Inputs, fields: Fields): boolean {
  return isCandlestick(inputs) && !!inputs.config?.x && fields.some((f) => f.name === inputs.config.x?.key);
}

/**
 * Checks if a valid open field is selected for candlestick chart
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if candlestick open requirement is met
 */
export function checkCandlestickOpen(inputs: Inputs, fields: Fields): boolean {
  return isCandlestick(inputs) && !!inputs.config?.open && fields.some((f) => f.name === inputs.config.open?.key);
}

/**
 * Checks if a valid high field is selected for candlestick chart
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if candlestick high requirement is met
 */
export function checkCandlestickHigh(inputs: Inputs, fields: Fields): boolean {
  return isCandlestick(inputs) && !!inputs.config?.high && fields.some((f) => f.name === inputs.config.high?.key);
}

/**
 * Checks if a valid low field is selected for candlestick chart
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if candlestick low requirement is met
 */
export function checkCandlestickLow(inputs: Inputs, fields: Fields): boolean {
  return isCandlestick(inputs) && !!inputs.config?.low && fields.some((f) => f.name === inputs.config.low?.key);
}

/**
 * Checks if a valid close field is selected for candlestick chart
 * @param inputs The visualization inputs configuration
 * @param fields Available column type pairs
 * @returns boolean indicating if candlestick close requirement is met
 */
export function checkCandlestickClose(inputs: Inputs, fields: Fields): boolean {
  return isCandlestick(inputs) && !!inputs.config?.close && fields.some((f) => f.name === inputs.config.close?.key);
}

type Requirement = {
  label: string;
  isMet: (inputs: Inputs, fields: Fields) => boolean;
};

const xyRequirements: Requirement[] = [
  {
    label: "The X Axis requires a field to be chosen",
    isMet: checkXAxisField,
  },
  {
    label: "The Y Axis requires at least one field to be chosen",
    isMet: checkYAxisFields,
  },
];

const pieRequirements: Requirement[] = [
  {
    label: "The Pie requires a slice field value to be chosen",
    isMet: checkPieSliceField,
  },
  {
    label: "The Pie requires a value field to be chosen",
    isMet: checkPieValueField,
  },
];

const chartRequirements: Record<VisualizationType, Requirement[]> = {
  bar: xyRequirements,
  "bar-stacked": xyRequirements,
  "bar-line": xyRequirements,
  "bar-normalized": xyRequirements,
  "bar-horizontal": xyRequirements,
  "bar-horizontal-stacked": xyRequirements,
  "bar-horizontal-normalized": xyRequirements,
  line: xyRequirements,
  area: xyRequirements,
  "area-stacked": xyRequirements,
  "area-normalized": xyRequirements,
  scatter: xyRequirements,
  pie: pieRequirements,
  "viz-table": [
    {
      label: "At least one column should be selected",
      isMet: checkTableColumns,
    },
  ],
  "big-number": [
    {
      label: "The Primary Number Field should be chosen",
      isMet: checkBigNumberField,
    },
  ],
  histogram: [
    {
      label: "The value field should be chosen",
      isMet: checkHistogramValueField,
    },
  ],
  heatmap: [
    {
      label: "The X Axis requires a field to be chosen",
      isMet: checkHeatmapXAxis,
    },
    {
      label: "The Y Axis requires a field to be chosen",
      isMet: checkHeatmapYAxis,
    },
    {
      label: "The value field should be chosen",
      isMet: checkHeatmapValueField,
    },
  ],
  candlestick: [
    {
      label: "The X Axis requires a field to be chosen",
      isMet: checkCandlestickXAxis,
    },
    {
      label: "Open requires a field to be chosen",
      isMet: checkCandlestickOpen,
    },
    {
      label: "High requires a field to be chosen",
      isMet: checkCandlestickHigh,
    },
    {
      label: "Low requires a field to be chosen",
      isMet: checkCandlestickLow,
    },
    {
      label: "Close requires a field to be chosen",
      isMet: checkCandlestickClose,
    },
  ],
};

export const visualizationTypeLabels: Record<VisualizationType, string> = {
  bar: "Bar Chart",
  "bar-stacked": "Stacked Bar Chart",
  "bar-normalized": "Normalized Bar Chart",
  "bar-horizontal": "Horizontal Bar Chart",
  "bar-horizontal-stacked": "Horizontal Stacked Bar Chart",
  "bar-horizontal-normalized": "Horizontal Normalized Bar Chart",
  line: "Line Chart",
  area: "Area Chart",
  "area-stacked": "Stacked Area Chart",
  "area-normalized": "Normalized Area Chart",
  scatter: "Scatter Plot",
  "bar-line": "Bar-Line Chart",
  pie: "Pie Chart",
  "viz-table": "Table",
  "big-number": "Big Number",
  histogram: "Histogram",
  heatmap: "Heatmap",
  candlestick: "Candlestick",
};

export const hasAllRequiredFields = (
  input: Inputs,
  fields: visualization.v3.ColumnTypePair[],
): { areRequirementsMet: boolean; requirementsStatus: RequirementsStatus } => {
  const requirements = chartRequirements[input.type] || [];

  const requirementsStatus = requirements.map((req) => ({
    message: req.label,
    met: req.isMet(input, fields),
  }));

  const areRequirementsMet = requirementsStatus.every((status) => status.met);

  return { areRequirementsMet, requirementsStatus };
};

export type RequirementsStatus = { message: string; met: boolean }[];
