import { queryRun, visualization } from "@fscrypto/domain";
import { BigNumberOptionsBuilder } from "./big-number";
import { PieOptionsBuilder } from "./pie";
import { TableOptionsBuilder } from "./table";
import { ChartBuilder, createFields, stringifyUnsupportedKeys } from "./util";
import {
  AreaOptionsBuilder,
  BarLineOptionsBuilder,
  BarOptionsBuilder,
  HistogramOptionsBuilder,
  LineOptionsBuilder,
  ScatterOptionsBuilder,
} from "./xy";
import { CandlestickOptionsBuilder } from "./xy/candlestick";
import { HeatmapOptionsBuilder } from "./xy/heatmap";

export const BuilderConstructor: {
  [K in visualization.v3.Inputs["type"]]: {
    new (input: Extract<visualization.v3.Inputs, { type: K }>, options: Highcharts.Options): ChartBuilder;
  };
} = {
  "area-normalized": AreaOptionsBuilder,
  "area-stacked": AreaOptionsBuilder,
  "bar-horizontal": BarOptionsBuilder,
  "bar-horizontal-normalized": BarOptionsBuilder,
  "bar-horizontal-stacked": BarOptionsBuilder,
  "bar-line": BarLineOptionsBuilder,
  "bar-normalized": BarOptionsBuilder,
  "bar-stacked": BarOptionsBuilder,
  "big-number": BigNumberOptionsBuilder,
  "viz-table": TableOptionsBuilder,
  area: AreaOptionsBuilder,
  bar: BarOptionsBuilder,
  candlestick: CandlestickOptionsBuilder,
  heatmap: HeatmapOptionsBuilder,
  histogram: HistogramOptionsBuilder,
  line: LineOptionsBuilder,
  pie: PieOptionsBuilder,
  scatter: ScatterOptionsBuilder,
};

export const optionsBuilder = (
  { inputs, options }: visualization.v3.Visualization["config"],
  r: queryRun.QueryRunResult,
): [Highcharts.Options, string[]] => {
  if (!inputs) return [options, [] as string[]];

  // JSON.stringify data from unsupported keys
  const fields = createFields(r);
  const unsupportedKeys = fields.filter((f) => !visualization.v3.isSupportedDataType(f.type)).map((f) => f.name);
  const transformedData =
    unsupportedKeys.length > 0 ? stringifyUnsupportedKeys(r.jsonData, unsupportedKeys) : r.jsonData;

  // run the appropriate builder
  const builder = new BuilderConstructor[inputs.type](inputs as never, options);
  const data = builder.getData(transformedData, fields);
  const dataKeys = builder.getDataKeys(data);
  return [builder.build(data), dataKeys];
};
