import { PieInputs } from "@fscrypto/domain/visualization/v3";
import * as R from "remeda";
import { categorical } from "../colors/default";
import { groupData } from "../data";
import { smallerTitleMargin } from "./util";

export class PieOptionsBuilder {
  constructor(
    public readonly inputs: PieInputs,
    public readonly options: Highcharts.Options = {},
  ) {}

  build(data: Record<string, unknown>[]): Highcharts.Options {
    const series = this.getSeries(data);
    const { slice, value } = this.inputs.config;
    if (!series || !slice || !value) return { ...this.options };
    return R.mergeDeep(
      {
        title: smallerTitleMargin(this.options.title),
        series: [series],
        tooltip: {
          formatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return [
              `<span style="color:${this.color}">` +
                `${slice.name ?? slice.key}: ` +
                `<b>${this.key}</b><br/>` +
                `${value.name ?? value.key}: ` +
                `<b>${this.y}</b><br/>` +
                `${this.percentage.toFixed(2)}%` +
                `</span>`,
            ];
          },
        },
        colors: this.options.colors ?? categorical,
      },
      this.options as Record<string, unknown>,
    );
  }

  getData(data: Record<string, unknown>[]) {
    const { slice, value } = this.inputs.config;
    if (!slice || !value) return data;
    return groupData(slice.key, value.key, slice.key, data);
  }

  getDataKeys(data: any[]) {
    return R.pipe(data, R.flatMap(Object.keys), R.unique());
  }

  getSeries(data: any[]): Highcharts.SeriesOptionsType | undefined {
    const { slice, value } = this.inputs.config;
    if (!slice || !value) return;
    return {
      type: "pie" as const,
      data: data.map((d) => ({
        name: d[slice.key],
        color: this.inputs.config.colors?.[d[slice.key]],
        y: Number(d[d[slice.key]]),
      })),
    };
  }
}
