import * as R from "remeda";

/**
 * Example:
 *
 * data = [{ day: "monday", price: 1, stock: 100 }, { day: "monday", price: 1, stock: 100 }, { day: "tuesday", price: 1, stock: 100 }]
 *
 * x = "day"
 *
 * y = ["price", "stock"]
 *
 * result = [{ day: "monday", price: 2, stock: 200 }, { day: "tuesday", price: 1, stock: 100 }]
 */
export const sumData = (data: Record<string, unknown>[], x: string, ys: string[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.tap((d) => console.log(d)),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce(
          (acc, d) => {
            const obj = { ...acc, [x]: d[x] };
            for (const y of ys) {
              obj[y] = (acc[y] ?? 0) + d[y];
            }
            return obj;
          },
          {} as Record<string, any>,
        ),
      ),
    ),
  );
};

export const countData = (data: Record<string, unknown>[], x: string) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce((acc, d) => ({ ...acc, [x]: d[x], count: (acc.count ?? 0) + 1 }), {} as Record<string, any>),
      ),
    ),
  );
};

/**
 * Example:
 *
 * data = [{ day: "monday", price: 0, stock: 0 }, { day: "monday", price: 5, stock: 500 }, { day: "tuesday", price: 1, stock: 100 }]
 *
 * x = "day"
 *
 * y = ["price", "stock"]
 *
 * result = [{ day: "monday", price: 2.5, stock: 250 }, { day: "tuesday", price: 1, stock: 100 }]
 */
export const averageData = (data: any[], x: string, ys: string[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x]),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.reduce(
          (acc, d) => {
            const obj = { ...acc, [x]: d[x] };
            for (const y of ys) {
              obj[y] = (acc[y] ?? 0) + d[y];
              obj[`count-${y}`] = ((acc[`count-${y}`] as number) ?? 0) + 1;
            }
            return obj;
          },
          {} as Record<string, unknown>,
        ),
      ),
    ),
    R.map((d) =>
      R.reduce(
        ys,
        (acc, y) => ({ ...acc, [x]: d[x], [y]: (d[y] as number) / (d[`count-${y}`] as number) }),
        {} as Record<string, unknown>,
      ),
    ),
  );
};

/**
 * Example:
 *
 * x = "date"
 * y = "sales"
 * key = "product"
 * data = [
 *   { date: "2024-01", product: "laptop", sales: 100 },
 *   { date: "2024-01", product: "phone", sales: 200 },
 *   { date: "2024-02", product: "laptop", sales: 150 },
 *   { date: "2024-02", product: "phone", sales: 250 }
 * ]
 *
 * result = [
 *   { date: "2024-01", laptop: 100, phone: 200 },
 *   { date: "2024-02", laptop: 150, phone: 250 }
 * ]
 */
export const groupData = <T extends Record<string, unknown>>(x: keyof T, y: keyof T, key: keyof T, data: T[]) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((group) => {
      // Initialize result object with the x value from first item in group
      // Then iterate through group items, using the key value as property name
      // and summing up the y values for each key
      const result: Record<string, unknown> = { [x]: group[0][x] };
      for (const item of group) {
        const keyValue = item[key] as string;
        if (!result[keyValue]) {
          result[keyValue] = 0;
        }
        result[keyValue] = (result[keyValue] as number) + (item[y] as number);
      }
      return result;
    }),
  );
};

/**
 * Example:
 *
 * x = "date"
 *
 * ys = ["usage", "rank"]
 *
 * key = "browser"
 *
 * data = [ { date: "1/1", browser: "firefox", usage: 0.2, rank: 1 },
 *
 *   { date: "1/1", browser: "chrome", usage: 0.8, rank: 2 },
 *
 *   { date: "1/2", browser: "firefox", usage: 0.7, rank: 2 },
 *
 *   { date: "1/2", browser: "chrome", usage: 0.3, rank: 1 }]
 *
 * result = [ { date: "1/1", "firefox": { usage: 0.2, rank: 1 }, "chrome": { usage: 0.8, rank: 2 } },
 *
 *   { date: "1/2", "firefox": { usage: 0.7, rank: 2 }, "chrome": { usage: 0.3, rank: 1 } } ]
 */
export const groupDataMulti = <T extends Record<string, unknown>>(
  x: keyof T,
  ys: (keyof T)[],
  key: keyof T,
  data: T[],
) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((d) =>
      R.pipe(
        d,
        R.map((d) => ({ [x]: d[x], [d[key] as string]: Object.fromEntries(ys.map((y) => [y, d[y]])) })),
        R.reduce((acc, x) => R.merge(acc, x), {} as Record<string, any>),
      ),
    ),
  );
};

/**
 * Example:
 *
 * x = "date"
 *
 * y = "usage"
 *
 * line = "rank"
 *
 * key = "browser"
 *
 * data = [ { date: "1/1", browser: "firefox", usage: 0.2, rank: 1 },
 *
 *   { date: "1/1", browser: "chrome", usage: 0.8, rank: 2 },
 *
 *   { date: "1/2", browser: "firefox", usage: 0.7, rank: 2 },
 *
 *   { date: "1/2", browser: "chrome", usage: 0.3, rank: 1 }]
 *
 * result = [ { date: "1/1", "firefox": 0.2, "chrome": 0.8, rank: 3 },
 *
 *   { date: "1/2", "firefox": 0.7, "chrome": 0.3, rank: 3 } ]
 */
export const groupAndSum = <T extends Record<string, unknown>>(
  x: keyof T,
  y: keyof T,
  line: keyof T,
  key: keyof T,
  data: T[],
) => {
  return R.pipe(
    data,
    R.groupBy((d) => d[x] as string),
    R.values(),
    R.map((group) => {
      // Initialize result object with the x value from first item in group
      const result: Record<string, unknown> = { [x]: group[0][x] };

      // Sum up the line values
      const lineSum = R.pipe(
        group,
        R.map((d) => d[line]),
        R.sum,
      );
      result[line as string] = lineSum;

      // Sum up the y values for each key
      for (const item of group) {
        const keyValue = item[key] as string;
        if (!result[keyValue]) {
          result[keyValue] = 0;
        }
        result[keyValue] = (result[keyValue] as number) + (item[y] as number);
      }

      return result;
    }),
  );
};
