import { visualization } from "@fscrypto/domain";
import { HighchartsReact } from "highcharts-react-official";
import { merge } from "lodash-es";
import { ErrorBoundary } from "react-error-boundary";
import { BuilderConstructor } from "./builders";
import { Highcharts } from "./lib/highcharts.client";
import baseTheme from "./themes/base-theme";
import darkTheme from "./themes/dark-theme";

interface ChartProps {
  input: visualization.v3.Inputs;
  options: Highcharts.Options;
  dark?: boolean;
  immutable?: boolean;
  constructorType: string;
}

export const Chart = ({ input, options, constructorType, immutable = true, dark = false }: ChartProps) => {
  const builder = new BuilderConstructor[input.type](input as never, options);
  const builderTheme = builder.themedOptions?.(dark ? "dark" : "light");
  const themedOptions = dark
    ? merge({}, darkTheme, builderTheme, {
        ...options,
        yAxis: Array.isArray(options.yAxis)
          ? options.yAxis.map((axis) => merge({}, darkTheme.yAxis, axis))
          : options.yAxis,
      })
    : merge({}, baseTheme, builderTheme, {
        ...options,
        yAxis: Array.isArray(options.yAxis)
          ? options.yAxis.map((axis) => merge({}, baseTheme.yAxis, axis))
          : options.yAxis,
      });
  return (
    <ErrorBoundary FallbackComponent={ChartError}>
      <HighchartsReact
        constructorType={constructorType}
        options={themedOptions}
        highcharts={Highcharts}
        containerProps={{ className: "h-full w-full" }}
        immutable={immutable}
      />
    </ErrorBoundary>
  );
};

const ChartError = (props: { error: { message: string } }) => {
  console.log(props);
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div>
        <div>Sorry there was an error rendering the chart</div>
        <pre>{props.error.message}</pre>
      </div>
    </div>
  );
};
