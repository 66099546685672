import { queryRun, visualization } from "@fscrypto/domain";
import { TableInputs } from "@fscrypto/domain/visualization/v3";
import { smallerTitleMargin } from "./util";

type DataAndFields = {
  data: Record<string, unknown>[];
  fields: visualization.v3.ColumnTypePair[];
};

export class TableOptionsBuilder {
  constructor(
    public readonly inputs: TableInputs,
    public readonly options: Highcharts.Options = {},
    public readonly results: any[] = [],
  ) {}

  getData(data: Record<string, unknown>[], fields: visualization.v3.ColumnTypePair[]): Record<string, unknown>[] {
    return [{ data, fields }] as [DataAndFields];
  }

  build(rawData: Record<string, unknown>[]) {
    const [{ data, fields: rawFields }] = rawData as [DataAndFields];

    // old visualizations don't have all the data in the correct format so we re-create it here for backwards compatibility
    const fields =
      rawFields.length === 0
        ? Object.keys(data[0]).map((name): visualization.v3.ColumnTypePair => ({ name, type: "text" }))
        : rawFields;
    const { columns, csvData, types } = filterCsvData(data, fields, this.inputs.config);

    return {
      ...this.options,
      title: smallerTitleMargin(this.options.title),
      table: { csvData, columns, types, resultsRetrieved: true } as visualization.v3.Options["table"],
    };
  }

  getDataKeys(): string[] {
    return [];
  }

  getSeries() {
    const record = this.inputs.config;
    return record;
  }
}

export interface TableOptions {
  columns: { title: string; visible: boolean }[];
  rows: { title: string; value: string }[][];
}

function filterCsvData(
  data: Record<string, unknown>[],
  fields: visualization.v3.ColumnTypePair[],
  config: TableInputs["config"],
): {
  columns: string[];
  csvData: queryRun.QueryRunResult["csvData"];
  types: queryRun.QueryRunResult["types"];
} {
  // Determine the indices of columns to keep and extract custom labels
  const visibleColumns = fields
    .map(({ name: column, type }) => ({
      column,
      type,
      customLabel: config.columnOptions[column]?.customLabel || column, // Use custom label if available, otherwise use column name
    }))
    .filter(({ column }) => config.columnOptions[column] && config.columnOptions[column].visible)
    .sort((a, b) => config.columnOptions[a.column].position - config.columnOptions[b.column].position);

  // Filter and reorder the CSV data based on visible columns
  const filteredCsvData = data.map((row) => visibleColumns.map(({ column }) => row[column]));

  // Filter and reorder the types based on visible columns
  const filteredTypes = visibleColumns.map(({ type }) => type);

  // Use custom labels for columns in the output if specified
  return {
    columns: visibleColumns.map(({ customLabel }) => customLabel),
    csvData: filteredCsvData,
    types: filteredTypes,
  };
}
