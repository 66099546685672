import { visualization } from "@fscrypto/domain";
import { BigNumberInputs, Options } from "@fscrypto/domain/visualization/v3";
import { smallerTitleMargin } from "./util";

export class BigNumberOptionsBuilder {
  constructor(
    public readonly inputs: BigNumberInputs,
    public readonly options: Highcharts.Options = {},
  ) {}

  build(rawVals: Record<string, unknown>[]): visualization.v3.Options {
    const [vals] = rawVals as [Options["bigNumber"]];
    return {
      ...(this.options as visualization.v3.Options),
      title: smallerTitleMargin(this.options.title),
      bigNumber: vals,
    };
  }

  getData(data: Record<string, unknown>[]): Record<string, unknown>[] {
    const { valueKey, rowNumber, caption, prefix, suffix, decimals, d3Format, autoFormat } = this.inputs.config;
    if (!valueKey)
      return [
        {
          primaryValue: undefined,
          secondaryValue: undefined,
          rows: data.length,
          prefix,
          suffix,
          decimals,
          d3Format,
          autoFormat,
          caption,
        } as NonNullable<Options["bigNumber"]>,
      ];
    const primaryValue = data[(rowNumber ?? 1) - 1]?.[valueKey!];
    // const secondaryValue = data[(secondaryRowNumber ?? 1) - 1][secondaryValueKey!];
    return [
      {
        primaryValue: primaryValue ? Number(primaryValue) : undefined,
        rows: data.length,
        prefix,
        suffix,
        decimals,
        d3Format,
        autoFormat,
        caption,
      } as NonNullable<Options["bigNumber"]>,
    ];
  }

  getDataKeys(rawData: Record<string, unknown>[]) {
    const [data] = rawData as [Options["bigNumber"]];
    return [data?.secondaryValue, data?.secondaryValue] as unknown as string[];
  }

  getSeries() {
    const record = this.inputs.config;
    return record;
  }
}
