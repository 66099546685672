import { Options } from "@fscrypto/domain/visualization/v3";
import { ParentSize } from "@visx/responsive";
import clsx from "clsx";
import { format } from "d3";

export const BigNumberContent = ({
  values,
  width,
  height,
  title = "",
}: {
  values: Options["bigNumber"];
  width: number;
  height: number;
  title?: string;
}) => {
  const { primaryValue = 0, caption, suffix = "", prefix = "", autoFormat, d3Format, decimals } = values ?? {};
  const size = getSize(width, height, primaryValue.toString().length);

  let value = "";
  // render auto formatted value if autoFormat is true, otherwise render d3 formatted value or non-formatted value
  if (autoFormat) {
    value = formatNumber(primaryValue, decimals);
  } else {
    if (d3Format) {
      try {
        value = format(d3Format)(primaryValue);
      } catch (e) {
        // catch and render non-formatted value to avoid an error state as the user types in the d3 format
        value = primaryValue.toString();
      }
    } else {
      value = primaryValue.toString();
    }
  }

  const formattedValue = prefix + value + suffix;
  return (
    <div className="dark:text-gray-30 flex h-full flex-col items-center justify-center p-4">
      {title?.length > 0 && <div className="text-content text-xl font-semibold">{title}</div>}
      <div
        className={clsx("flex w-full flex-1 items-center justify-center text-center tracking-tighter", {
          "text-9xl": size === "xxl",
          "text-8xl": size === "xl",
          "text-6xl": size === "lg",
          "text-4xl": size === "md",
          "text-2xl": size === "sm",
        })}
      >
        {formattedValue}
      </div>
      {caption && <div className="text-muted-foreground py-4 text-base">{caption}</div>}
    </div>
  );
};

export const BigNumber = ({ options }: { options: Options }) => {
  if (!options.bigNumber) return null;
  return (
    <div className="h-full w-full">
      <ParentSize>
        {({ width, height }) => (
          <BigNumberContent values={options.bigNumber} width={width} height={height} title={options.title?.text} />
        )}
      </ParentSize>
    </div>
  );
};

type size = "xxl" | "xl" | "lg" | "md" | "sm";

const getSize = (width: number, height: number, numberLength: number): size => {
  const ratio = Math.round(width / numberLength);

  if (width >= 600 && height >= 180 && ratio > 70) {
    return "xxl";
  } else if (width >= 400 && height >= 180 && ratio > 75) {
    return "xxl";
  } else if (width >= 260 && height >= 180 && ratio > 60) {
    return "xl";
  } else if (width >= 250) {
    return "lg";
  } else if (width >= 150) {
    return "md";
  } else {
    return "sm";
  }
};

function formatNumber(num: number, overrideDecimalCount?: number): string {
  // if passed an override decimal count, use that no matter what
  // check for undefined because 0 is a valid override
  const getDecimals = (count: number): number => {
    if (overrideDecimalCount !== undefined) {
      return Math.max(overrideDecimalCount, 0);
    } else {
      return count;
    }
  };

  let decimals = getDecimals(1);

  let formatString = "";
  let symbol = "";
  if (Math.abs(num) >= 1e9) {
    num = num / 1e9;
    formatString = `,.${decimals}f`;
    symbol = "b";
  } else if (Math.abs(num) >= 1e6) {
    num = num / 1e6;
    formatString = `,.${decimals}f`;
    symbol = "m";
  } else if (Math.abs(num) >= 1e3) {
    num = num / 1e3;
    formatString = `,.${decimals}f`;
    symbol = "k";
  } else if (Math.abs(num) < 1e-6 && Math.abs(num) > 0) {
    num = num * 1e6;
    formatString = `,.${decimals}f`;
    symbol = "µ";
  } else {
    if (Math.abs(num) < 1 && Math.abs(num) >= 0.1) {
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.1 && Math.abs(num) >= 0.01) {
      decimals = getDecimals(2);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.01 && Math.abs(num) >= 0.001) {
      decimals = getDecimals(3);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.001 && Math.abs(num) >= 0.0001) {
      decimals = getDecimals(4);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.0001 && Math.abs(num) >= 0.00001) {
      decimals = getDecimals(5);
      formatString = `,.${decimals}f`;
    } else if (Math.abs(num) < 0.00001 && Math.abs(num) >= 0.000001) {
      decimals = getDecimals(6);
      formatString = `,.${decimals}f`;
    } else {
      formatString = `,.${decimals}f`;
    }
  }

  // this will remove trailing zeros
  const formattedNum = format(formatString)(num);
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  return formattedNum.replace(regex, "$1") + symbol;
}
