import { queryRun } from "@fscrypto/domain";
import { optionsBuilder } from "@fscrypto/viz-2";
import { createFields } from "@fscrypto/viz-2";
import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { combineLatest, map } from "rxjs";
import { useVisualization } from ".";

export const useVisualizationConfigBuilder = (id: string, result?: queryRun.QueryRunResult) => {
  const viz = useVisualization(id);
  const results$ = useObservable(pluckFirst, [result]);
  const config$ = useObservable(pluckFirst, [viz?.value?.config]);
  const optionsAndData$ = useObservable(() =>
    combineLatest([results$, config$]).pipe(
      map(([r, c]): [Highcharts.Options | undefined, string[] | undefined] => {
        if (!r || !c) return [undefined, undefined];
        return optionsBuilder(c!, r!);
      }),
    ),
  );

  const fields = result ? createFields(result) : [];
  const [options, dataKeys] = useObservableState(optionsAndData$, [
    viz?.value?.config?.options as Highcharts.Options,
    [] as string[],
  ]);
  return { options, fields, inputs: viz?.value?.config?.inputs, dataKeys };
};
