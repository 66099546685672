import { queryRun, visualization } from "@fscrypto/domain";
import type { TitleOptions } from "highcharts";
import * as R from "remeda";

export const smallerTitleMargin = (existingOptions?: TitleOptions): visualization.v3.Options["title"] => ({
  margin: -10,
  ...existingOptions,
});

export type ChartBuilder = {
  getData: (result: Record<string, unknown>[], fields: visualization.v3.ColumnTypePair[]) => Record<string, unknown>[];
  getDataKeys: (data: Record<string, unknown>[]) => string[];
  build: (data: Record<string, unknown>[]) => Highcharts.Options;
  themedOptions?: (theme: "light" | "dark") => Highcharts.Options;
};

export const createFields = (results: queryRun.QueryRunResult) => {
  const fields = R.zip(results?.columns ?? [], results?.types ?? []).map(([name, type]) => ({ name, type }));
  return fields;
};

export const stringifyUnsupportedKeys = (data: Record<string, unknown>[], keys: string[]) =>
  data.map((d) => ({ ...d, ...Object.fromEntries(keys.map((key) => [key, JSON.stringify(d[key])])) }));
